<template>
  <div class="app-container">
      <div class="top_btns">
        <div class="left_box">
          <el-date-picker v-model="listQuery.year" type="year" value-format="YYYY" placeholder="选择年份"  @change="getList" size="small" style="width:120px"/>
          <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;margin-left:10px" class="filter-item" size="small" @keyup.enter="getList" />
          <el-button class="filter-item1" type="primary" size="small" @click="getList" style="margin-right:10px">
            <el-icon><Search /></el-icon><span  > 搜索</span>
          </el-button>
          <search @success="getList">
            <el-form style="margin:11px 0px 0px 6px;width:600px" class="styleForm">
              <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
                  <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
                </el-form-item>
              <el-form-item label="采集状态：" :label-width="formLabelWidth">
                <el-checkbox-group size="small" v-model="listQuery.taskStatus">
                  <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </search>
        </div>
        <div class="right_box">
          <el-button size="small" plain type="warning" @click="declaration"   >
            <el-icon><Money /></el-icon><span  > 批量采集</span>
          </el-button>
        </div>
      </div>
      <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" width="60" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="240" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            <span style="cursor: pointer;color:#39b0d2" @click="$goEtax(scope.row.comId)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="采集结果" min-width="180">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus, scope.row.bussinessStatus)"></i>
                <el-tooltip v-if="scope.row.bussinessLog" class="scope.row" effect="dark" :content="scope.row.businessLog?scope.row.businessLog:'' + scope.row.errLog?scope.row.errLog:''" placement="top-start">
                  <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</el-button>
                </el-tooltip>
                <span v-else>
                  <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</el-button>
                </span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <el-button v-if="scope.row.bussinessStatus == 2" @click="checkTable(scope.row)" size="small" type="text">查看</el-button>
              <el-button @click="handleUpdate(scope.row)" size="small" type="text">采集</el-button>
              <el-button @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>

    <declareDetails ref="declareDetails"></declareDetails>
    <checkYearTable ref="checkYearTable"></checkYearTable>
  </div>
</template>

<script>
import { companyYearList } from "@/api/company"

import { sendTask } from '@/api/collection'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import checkYearTable  from "./checkYearTable.vue"


export default {
  name: 'tax-year-tax',
  components:{
    selecttaxtype,
    declareDetails,
    checkYearTable
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        status:2,
        taskName:'tax-year-cj',
        name: '',
        taskStatus: [],
        businessStatus:[],
        aliStatus:[],
        year:'2022'
      },
      total:0,
      list:[],
      comIds: [],
      options: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        }
      ],
    };
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(280)

  },
  mounted() {
    
  },
  methods: {
    getList(){
      companyYearList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleSelectionChange(row) {
      this.comIds = []
      row.map(info=>{
        this.comIds.push(info.comId)
      })
    },
    declaration(){
      if(!this.comIds.length){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        taskName: 'tax-year-cj',
        comIds: this.comIds,
        period:this.listQuery.year

      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    handleUpdate(row){
      let param = {
        taskName: 'tax-year-cj',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.year,'tax-year-cj')
    },

    checkTable(row){
      let param = {
        comId:row.comId,
        year:'2022',
        tableName:'year_a000000_cj'
      }
      this.$refs.checkYearTable.getList(row)
    }
  },
};
</script>

<style lang="scss" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.sbcg {
  padding: 0 !important;
}
</style>
<template>
  <div class="app-container">
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="我的申报" name="我的申报"></el-tab-pane>
      <el-tab-pane label="学生申报" name="学生申报" v-if="this.userRoleName != '学生'"></el-tab-pane>

    <div class="top_btns">
      <div class="left_box">
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;margin-left:10px" class="filter-item" size="small" @keyup.enter="getList" />
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 6px;width:600px" class="styleForm">
            <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
              <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择班级 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生' && activeName != '我的申报'">   
              <selectClass v-model:deptId="listQuery.deptId" @success="getUserList" style="margin-left:5px"></selectClass>
            </el-form-item>
            <el-form-item label="选择人员：" :label-width="formLabelWidth"  v-if="this.userRoleName != '学生' && activeName != '我的申报'">
              <selectuser ref="selectuser" code="bookkeeping" eduCode="" v-model:userId="listQuery.userIds" @success="getUser"></selectuser>
            </el-form-item>
            <el-form-item label="扣款结果：" :label-width="formLabelWidth">
              <selectBussinessKkStatus v-model:allStatus="listQuery.allStatus"></selectBussinessKkStatus>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" type="warning" @click="declaration()"   >
          <el-icon><Money /></el-icon><span  > 批量扣款</span>
        </el-button>
        <el-button size="small" type="danger" @click="cancelTask()"   >
          <el-icon><List /></el-icon><span  > 批量撤销</span>
        </el-button>
      </div>
    </div>
    <div class="loudou">
      <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" width="60" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="240" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            <span style="cursor: pointer;color:#39b0d2" @click="$goEtax(scope.row.comId)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报税种" width="280"
        :filters="this.categories">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p style="text-align: left;">{{$tableNameFilter(item.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
<!-- 申报 -->
        <el-table-column align="left" prop="districtName" label="申报结果" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
                <div class="item_icon">
                  <p>
                    <span v-if="item.image" class="demo-image__preview" >
                      <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="open(item, index, scope.$index)"></i>
                    </span>
                    <i :class="$batchSendIconSb(item.taskStatusSb, item.bussinessStatusSb)"></i>
                    <el-tooltip class="item" effect="dark" :content="item.businessLogSb" :disabled="!item.businessLogSb" placement="top-start">
                      <el-button size="small" :class="$batchSendWordSb(item.taskStatusSb, item.bussinessStatusSb)" style="border: 0;font-size: 14px;">{{$batchSendSb(item.taskStatusSb,item.bussinessStatusSb)}}</el-button>
                    </el-tooltip>
                  </p>
                </div>
            </div>
          </template>
        </el-table-column>


        <el-table-column align="left" prop="districtName" label="应补（退）税额" min-width="120">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.tax">{{item.tax}}</p>
              <p v-else>{{0}}</p>
            </div>
          </template>
        </el-table-column>
        
        <!-- 扣款 -->
        <el-table-column align="left" prop="districtName" label="任务状态" min-width="210">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div class="item_icon">
                <p>
                  <i :class="$batchSendIconSb(item.taskStatus, item.bussinessStatus)"></i>
                  <span :class="$batchSendWordKk(item.taskStatus,item.bussinessStatus)">{{$batchSendKk(item.taskStatus,item.bussinessStatus)}}</span>
                  {{item.endAt}}
                  <span style="color: #67c23a;">金额：</span>
                  <span v-if="item.amountPaid">{{item.amountPaid}}</span>
                  <!-- <span v-else>{{0}}</span> -->
                </p>

              </div>
            </div>
          </template>
        </el-table-column>

        
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <el-button @click="handleUpdate(item)" size="small" type="text">缴费</el-button>
              <el-button @click="declareDetail(scope.row,item)" size="small" type="text">缴费申报记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    </el-tabs>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>

</template>

<script>
import { companySendTaxList } from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask, quitTask } from "@/api/collection"
import sbImgUrl from "./sbImgUrl.vue"
import selectTaskStatus from "./select/selectTaskSatatus.vue"
import selectBussinessStatus from "./select/selectBussinessStatus.vue"

import selectKkTasknameStatus from "./select/selectKkTasknameStatus.vue"
import selectBussinessKkStatus from "./select/selectBussinessKkStatus.vue"

export default {
  name: "batch-send-tax",
  components:{
    selecttaxtype,
    declareDetails,
    sbImgUrl,
    selectTaskStatus,
    selectBussinessStatus,
    selectKkTasknameStatus,
    selectBussinessKkStatus

  },
  data(){
    return {
      activeName:'我的申报',
      formLabelWidth:'100px',
      listQuery: {
        page: 1,
        limit: 20,
        // status:'2',
        taskName:'tax-kk-',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        taskStatusSb:[],
        businessStatus:[],
        businessStatusSb:[],
        allStatus: [],
        accountingType: 2,
        taxNames:[],
        taxStatus: 1,
        userIds:[this.$store.getters['user/user'].userId]
        // comId: this.$store.getters['user/comInfo'].id*1,
      },
      total:0,
      list:[],
      ids: undefined,
      pageStatus: true,
      reqNos: [],
      categories: [
        {text: '利润表', value: 'kj_lrb'}, 
        {text: '资产负债表', value: 'kj_zcfz'}, 
        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        // {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        {text: '工会经费', value: 'gs_ghjf'},
        {text: '房产税土地税', value: 'gs_fcs'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_cb'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_jb'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '工会经费', value: 'gs_qtsr'},
        // {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
      ],
      kkStatus: undefined,  //交款状态
      userRoleName:this.$store.getters['user/user'].userRoleName

    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(300)
    this.initBus()
  },
  methods:{
    cancelTask(){
      this.$confirm('确定撤销扣款任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quitTask({reqNos:this.reqNos}).then(res => {
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
          }
        })
      })
    },
    
    initBus(){
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.ids && this.ids.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          res.items.forEach(item => {
            if(item.taskStatus == 1 || item.taskStatus == 2) {
              status = true
            }
          })
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    getList(){
      companySendTaxList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleClick(tab) {
      this.listQuery.userIds = []
      if(tab.props.name == "我的申报") {
        this.listQuery.userIds = [this.$store.getters['user/user'].userId]
        this.getList()
      }if(tab.props.name == "学生申报") {
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    // 缴费详情
    declareDetail(e,v) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,v.taskName)
    },
    handleSelectionChange(row) {
      row.map(item => {
        item.items.map(res => {
          let status = false
          if(res.taskStatus != 3) {
            status = true
          }else {
            status = false
          }
          this.kkStatus = status
        })
      })
      // row.taskStatus
      this.ids = []
      this.reqNos = []
      row.map(info=>{
        info.items.map(item=>{
          // this.gsMainIds.push({gsMainId: item.gsMainId, gsMainStatus: item.gsMainStatus})
          this.ids.push(item.gsMainId)
          this.reqNos.push(item.reqNo)
        })
      })
    },
    //过滤申报状态
    filterGsmainSbStatus(gsMainId){
      let status = false
      this.list.map(info=>{
        info.items.map(item=>{
          if(item.gsMainId == gsMainId  && item.taskStatusSb == 3){
            status = true
          }
        })
      })
      return status
    },
    //批量缴费
    declaration() {
      if(!this.ids){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let status  = true
      this.ids.map(gsMainId=>{
        let s = this.filterGsmainSbStatus(gsMainId)
        if(!s){
          status = false
        }
      })

      if(!status) {
        this.$qzfMessage('请先进行申报',1)
        return
      }
      let param = {
        taskName: 'tax_kk',
        gsMainIds: this.ids
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)

        }
      })
    },
    //缴费
    handleUpdate(e) {
      if(e.taskStatusSb == 3) {
        this.$confirm('确定要缴费吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let param = {
            taskName: 'tax_kk',
            gsMainIds: [e.gsMainId]
          }
          sendTask(param).then(res => {
            if(res.data.msg == 'success') {
              this.$qzfMessage('任务已发起',3)
              this.getList()
              this.$queueMessage(res.data.data.queue)

            }
          })
        })
      }else {
        this.$qzfMessage('请先进行申报',1)
      }
    },
    open(row, index, bigIndex) {
      let index2 = 0
      let imageAll = []
      let i3 = 0 
      this.list.map((v,i) => {
        v.items.map((res,i2)=>{
          index2++
          let url = {
            name: v.name,
            url: ""
          }
          if(i==bigIndex && i2 == index){
            i3 = index2 - 1
          }
          url.url = res.image ? 'https://file.listensoft.net/' + res.image + "?" + Math.random(10): ""
          imageAll.push(url)
        })
      })
      console.log(imageAll);
      this.$refs.sbImgUrl.dialog(imageAll, i3)
    },
    getUserList(){
      this.$refs.selectuser.init(this.listQuery.deptId)
    },
    getUser(e){
      this.listQuery.userIds = []
      if(this.listQuery.userIds.length == 0){
        e.map(v => {
          this.listQuery.userIds.push(v.id)
        })
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  // line-height: 50px;
  border-bottom: 1px solid #eee;
  // text-indent: 1em;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon p{
  
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 14px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }
}
.timeJe {
  display: flex;
  justify-content: space-around;;
}
</style>
<style lang="scss">
.none-border .cell {
  padding: 0;
}
.el-table__column-filter-trigger i {
  color: black !important;
}
.el-table th.el-table__cell>.cell.highlight {
  color: black !important;
}
.loudou .el-icon-arrow-down:before{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>
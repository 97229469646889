<template>
  <div>
    <el-dialog :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="查看报表数据"
      width="90%"
      destroy-on-close
      >
      <el-tabs type="border-card">
        <el-tab-pane label="企业所得税年度纳税申报基础信息表">
          <A000000 :comId="comId" tableYearName="year_a000000_cj"></A000000>
        </el-tab-pane>
        <el-tab-pane label="企业所得税弥补亏损明细表">
          <A106000 :comId="comId" tableYearName="year_a106000_cj"></A106000>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import A000000 from "../../report/yearTax/A000000.vue"
import A106000 from "../../report/yearTax/A106000.vue"


export default {
  name: 'checkYearTable',
  components:{
    A000000,
    A106000
  },
  data() {
    return {
      dialogFormVisible:false,
      comId:''
    };
  },

  mounted() {
    
  },

  methods: {
    getList(e){
      this.comId = e.comId
      this.dialogFormVisible =true
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
<template>
	<el-checkbox-group size="small" v-model="allStatus">
		<el-checkbox-button v-for="item in options" :key="item.value" :label="item.value" @change="changeValue">{{item.label}}</el-checkbox-button>
	</el-checkbox-group>
  
</template>

<script>
export default {
  name: 'selectBussinessStatus',
  props:{
		allStatus:""
	}, 
  data() {
    return {
      options: [
        {
          value: "task-0",
          label: '未扣款',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '扣款中',
        },
        {
          value: "business-2",
          label: '扣款成功',
        },
        {
          value: "business-3",
          label: '扣款失败',
        },
      ],
    };
  },
  mounted() {
    
  },
  methods: {
    changeValue() {
      this.$emit('update:allStatus', this.allStatus)
      this.$emit("success",this.allStatus)
    },   
  },
};
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="app-container">

    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="财务报表年报" name="财务报表年报"></el-tab-pane>
      <el-tab-pane label="企业所得税年报" name="企业所得税年报"></el-tab-pane>

      <div class="top_btns">
        <div class="left_box">
          <el-date-picker v-model="listQuery.year" type="year" value-format="YYYY" placeholder="选择年份"  @change="getList" size="small" style="width:120px"/>
          <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;margin-left:10px" class="filter-item" size="small" @keyup.enter="getList" />
          <el-button class="filter-item1" type="primary" size="small" @click="getList" style="margin-right:10px">
            <el-icon><Search /></el-icon><span  > 搜索</span>
          </el-button>
          <search @success="getList">
            <el-form style="margin:11px 0px 0px 6px;width:600px" class="styleForm">
              <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
                  <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
                </el-form-item>
              <el-form-item label="申报状态：" :label-width="formLabelWidth">
                <el-checkbox-group size="small" v-model="listQuery.taskStatusSb">
                  <selectBussinessStatus v-model:allStatus="listQuery.allStatus"></selectBussinessStatus>
                  <!-- <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button> -->
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </search>
        </div>
        <div class="right_box">
          <el-button size="small" plain type="warning" @click="declaration"   >
            <el-icon><Money /></el-icon><span  > 批量申报</span>
          </el-button>
        </div>
      </div>
      <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange" v-loading="loading">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" width="60" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="240" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            <span style="cursor: pointer;color:#39b0d2" @click="$goEtax(scope.row.comId)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报结果" min-width="180">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$batchSendIconSb(scope.row.taskStatus, scope.row.bussinessStatus)"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''" :disabled="!scope.row.errLog && !scope.row.bussinessLog" placement="top-start">
                  <el-button size="small" :class="$batchSendWordSb(scope.row.taskStatus, scope.row.bussinessStatus)" style="border: 0;font-size: 14px;">{{$batchSendSb(scope.row.taskStatus,scope.row.bussinessStatus)}}</el-button>
                </el-tooltip>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <el-button @click="handleUpdate(scope.row)" size="small" type="text">申报</el-button>
              <el-button @click="declareDetail(scope.row)" size="small" type="text">申报记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </el-tabs>


    <declareDetails ref="declareDetails"></declareDetails>
  </div>
</template>

<script>
import { companyYearList } from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { sendTask } from "@/api/collection"
import selectBussinessStatus from "./select/selectBussinessStatus.vue"




export default {
  name: 'tax-year-tax',
  components:{
    selecttaxtype,
    declareDetails,
    selectBussinessStatus
  },
  data() {
    return {
      activeName:'财务报表年报',
      listQuery: {
        page: 1,
        limit: 20,
        status:2,
        taskName:'tax-cwyear-sb',
        name: '',
        taskStatusSb: [],
        businessStatus:[],
        aliStatus:[],
        year:'2022'
      },
      total:0,
      list:[],
      comIds: [],
      loading:false,  //表格加载
      allStatus: [],
      contentStyleObj:{}
    };
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(280)
  },
  mounted() {
    
  },
  methods: {
    getList(){
      this.loading = true
      companyYearList(this.listQuery).then(res=>{
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleClick(tab) {
      if(tab.props.name == "财务报表年报") {
        this.listQuery.taskName = 'tax-cwyear-sb'
        this.getList()
      }if(tab.props.name == "企业所得税年报") {
        this.listQuery.taskName = 'tax-year-sb'
        this.getList()
      }
    },
    handleSelectionChange(row) {
      this.comIds = []
      row.map(info=>{
        this.comIds.push(info.comId)
      })
    },
    declaration(){
      if(!this.comIds.length){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        taskName: this.listQuery.taskName,
        comIds: this.comIds,
        period:this.listQuery.year
      }

      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    handleUpdate(row){
      let param = {
        taskName: this.listQuery.taskName,
        comIds: [row.comId],
        period:this.listQuery.year
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.year,this.listQuery.taskName)
    },
  },
};
</script>

<style lang="scss" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
</style>
<template>
  <div  v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 企业所得税弥补亏损明细表 -->
    <div class="big_box4">
      <div class="top_title3">
        <h5>企业所得税弥补亏损明细表</h5>
        <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>2020-2-25</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
         <td class="center" style="width:4%;" rowspan= '3'>行次</td>
         <td class="center" rowspan= '3' style="width:6%;line-height:20px">项目</td>
         <td class="center" rowspan= '2' style="width:4%;line-height:20px">年度</td>
         <td class="center" rowspan= '2' style="width:6%;line-height:20px">当年境内所得额</td>
         <td class="center" rowspan= '2' style="width:6%;line-height:20px">分立转出的亏损额</td>
         <td class="center" colspan='3' style="width:18%;line-height:20px">合并、分立转入的亏损额</td>
         <td class="center" rowspan= '2' style="width:6%;line-height:20px">弥补亏损企业类型</td>
         <td class="center" rowspan= '2' style="width:6%;line-height:20px">当年亏损额</td>
         <td class="center" rowspan= '2' style="width:6%;line-height:20px">当年待弥补的亏损额</td>
         <td class="center" colspan='2' style="width:12%;line-height:20px">用本年度所得额弥补的以前年度亏损额</td>
         <td class="center" rowspan= '2' style="width:6%;line-height:20px">当年可结转以后年度弥补的亏损额</td>
        </tr>

        <tr class="top_bg">
          <td class="center" style="line-height:20px">可弥补年限5年</td>
          <td class="center" style="line-height:20px">可弥补年限8年</td>
          <td class="center" style="line-height:20px">可弥补年限10年</td>
          <td class="center" style="line-height:20px">使用境内所得弥补</td>
          <td class="center" style="line-height:20px">使用境外所得弥补</td>
        </tr>


        <tr class="top_bg">
          <td class="center">1</td>
          <td class="center">2</td>
          <td class="center">3</td>
          <td class="center">4</td>
          <td class="center">5</td>
          <td class="center">6</td>
          <td class="center">7</td>
          <td class="center">8</td>
          <td class="center">9</td>
          <td class="center">10</td>
          <td class="center">11</td>
          <td class="center">12</td>
        </tr>
        <tr v-for="item in detail" :key="item.id">
          <td class="center">{{item.index}}</td>
          <td class="center" >{{item.project}}</td>
          <td class="center">{{item.a1}}</td>
          <td class="blue_box"><input type="text" v-model="item.a2"></td>
          <td class="blue_box"><input type="text" v-model="item.a3"></td>
          <td class="blue_box"><input type="text" v-model="item.a4"></td>
          <td class="blue_box"><input type="text" v-model="item.a5"></td>
          <td class="blue_box"><input type="text" v-model="item.a6"></td>
          <td class="blue_box"><input type="text" v-model="item.a7"></td>
          <td class="blue_box"><input type="text" v-model="item.a8"></td>
          <td class="blue_box"><input type="text" v-model="item.a9"></td>
          <td class="blue_box"><input type="text" v-model="item.a10"></td>
          <td class="blue_box"><input type="text" v-model="item.a11"></td>
          <td class="blue_box"><input type="text" v-model="item.a12"></td>
        </tr>
        
        <tr v-if="this.info.length >= 12">
          <td class="center">12</td>
          <td colspan='12'>可结转以后年度弥补的亏损额合计</td>
          <td class="blue_box"><input type="text" v-model="info[11].a12"></td>
        </tr>
      </table>
     <div class="save_box">
        <el-button type="primary" size="mini" @click="saveDetail()" v-if="!this.tableYearName">保存</el-button>
      </div>
    </div>
    
    <!-- 企业所得税弥补亏损明细表 end -->
  </div>
</template>
<script>
import { yearInfo, yearEdit,yearInfoCj } from "@/api/year"
export default {
  name: "A105060",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      comName: '',
      taxNo: '',
      info:[{"a1":''},{"a2":''},{"a3":''},{"a4":''},{"a5":''},{"a6":''},{"a7":''},{"a8":''},{"a9":''},{"a10":''},{"a11":''},{"a12":''}],
      detail:[{"a1":''},{"a2":''},{"a3":''},{"a4":''},{"a5":''},{"a6":''},{"a7":''},{"a8":''},{"a9":''},{"a10":''},{"a11":''},{"a12":''}],
      fullscreenLoading:false,
      query:undefined

    };
  },
  created() {
    this.comName = this.$store.getters['user/comInfo'].comName
    this.taxNo = this.$store.getters['user/comInfo'].taxNo
    this.getParams()
  },
  methods: {
    getParams() {
      // const tableName = this.$route.params.tableName;
      if(this.tableYearName){
        this.getDetail({comId:this.comId,year:'2022',tableName:this.tableYearName})
        this.listLoading = true;
        return
      }
      const tableName = 'year_a106000'
      this.tableName = tableName
      if (tableName) {
        this.getDetail({comId:this.comId,period:'2022',tableName:this.tableYearName});
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    getDetail(e) {
      if(this.tableYearName){
        yearInfoCj(e).then(response => {
          this.info = response.data.data;
          this.detail = this.info.slice(0,this.info.length-1)
        });
        return
      }
      yearInfo(e).then(response => {
        this.info = response.data.data;
        this.detail = this.info.slice(0,this.info.length-1)
      });
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      yearEdit({comId:this.comId,year:'2022',tableName:this.tableName,items:this.info}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.fullscreenLoading = false
        this.getDetail({comId:this.comId,period:'2022',tableName:this.tableYearName})

      });
    }
    
  }
};
</script>

<style lang="scss" scoped>
.border_none .el-select{
  width: 100% !important;
}
.center{
  text-align: center;
}
.right{
  text-align: right;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    // padding: 0 6px;
    font-size: 12px;
    text-align: right;
  }
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 5em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    // position: relative;
    h6{
      float: left;
      width: 33.33%;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      // position: absolute;
      // bottom: 0;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>




<template>
  <qzf-tabs v-model:value="value" @tab-click="handleClick">
    <qzf-tabs-item label="税种申报" name="1" :cache="false">
      <batchSendSz ref="batchSendSb" :activeName = "activeName"></batchSendSz>
    </qzf-tabs-item>
    <qzf-tabs-item label="税种缴款" name="2" :cache="false">
      <taxPaySz></taxPaySz>
    </qzf-tabs-item>
    <!-- <qzf-tabs-item label="汇算清缴申报" name="4" :cache="false">
      <TaxYearTax></TaxYearTax>
    </qzf-tabs-item>
    <qzf-tabs-item label="汇算清缴采集" name="3" :cache="false">
      <TaxYearTaxCj></TaxYearTaxCj>
    </qzf-tabs-item> -->
    
  </qzf-tabs>
  <!-- <div class="app-container">
    <initTable ref="initTable" :activeName = "activeName"></initTable>
  </div> -->
</template>

<script>
import batchSendSz from "./components/batchSendSz"
import taxPaySz from "./components/taxPaySz"
// import yearTax from '../report/yearTax/yearTaxList.vue'
import TaxYearTax from "./components/taxYearTax.vue"
import TaxYearTaxCj from "./components/TaxYearTaxCj.vue"

export default {
  name:'kjInit',
  components:{
    batchSendSz,
    taxPaySz,
    TaxYearTax,
    TaxYearTaxCj
},
  data(){
    return {
      activeName:'first',
      value:'1',
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj:[1],
        from:'init'
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>